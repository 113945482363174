<template>
    <div>
        <div v-if="!loading" class="rec-data-div" :class="pageMeta.component_name.toLowerCase()+'-page'" v-disable-all="!pageMeta.permissions.edit">
            <form @submit.prevent="updateRecord">
                <div class="flex space-between">
                    <div class="name-id">
                        <FormClient :client_id="client_id" :form_data_id="record_id" @getClient="getClient"/><br>
                    </div>
                    <div class="align-right">
                        <FormCompany @getCompany="getCompany"/>
                    </div>
                </div>
                <div>
                    <h2 class="margin-0">{{ pageMeta.title }}</h2>
                </div>

                <button type="button" @click="signature_ids=[]" v-if="signature_ids.length && original_signature_ids.length">
                    Edit & Create Addendum
                </button>
                <button v-if="original_signature_ids.length && !signature_ids.length" class="align-right" type="button" @click="signature_ids=original_signature_ids">
                    Cancel Edit
                </button>
                <div class="dark-text header-w-bg addendum" v-if="original_signature_ids.length && !signature_ids.length">
                    You are now editing the original signed document to create an addendum
                </div>

                <hr class="m-15" />

                <!-- put generated code BELOW here -->

                <div class="dark-text header-w-bg">Intake Questionnaire</div>
                <div class="dark-text top-20 bottom-20 font-13 bold">Please fill out the following to provide us with information to better know you.</div>
                <div class="flex colx3 space-between">
                    <Input type="text" v-model="rec.first_name" label="First Name" />
                    <Input type="text" v-model="rec.last_name" label="Last Name" />
                    <Input type="date" v-model="rec.dob" label="Date of Birth" />
                </div>
                <ExpandableTextArea
                    class="avoid-break block"
                    inputClass="block flex-1 do-not-print-me"
                    oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                    type="text"
                    id="reason_for_visit"
                    v-model="rec.reason_for_visit"
                    label="What brings you in to counseling at this time? Is there something specific you would like to address?"
                    :limit=1000
                    inputStyle="min-height:20px;"
                />
                <ExpandableTextArea
                    class="avoid-break block"
                    inputClass="block flex-1 do-not-print-me"
                    oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                    type="text"
                    id="therapy_goals"
                    v-model="rec.therapy_goals"
                    label="What are your goals for therapy?"
                    :limit=1000
                    inputStyle="min-height:20px;"
                />
                <Radios v-model="rec.previous_therapy" label="Have you seen a Mental Health professional before?" :options='["No","Yes"]' />
                <ExpandableTextArea
                    class="avoid-break block"
                    inputClass="block flex-1 do-not-print-me"
                    oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                    type="text"
                    id="past_treatment"
                    v-model="rec.past_treatment"
                    label="If yes, can you describe your treatment?"
                    :limit=1000
                    inputStyle="min-height:20px;"
                />
                <ExpandableTextArea
                    class="avoid-break block"
                    inputClass="block flex-1 do-not-print-me"
                    oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                    type="text"
                    id="medication_history"
                    v-model="rec.medication_history"
                    label="What medications are you presently taking and for what reason?"
                    :limit=1000
                    inputStyle="min-height:20px;"
                />
                <ExpandableTextArea
                    class="avoid-break block"
                    inputClass="block flex-1 do-not-print-me"
                    oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                    type="text"
                    id="provider_information"
                    v-model="rec.provider_information"
                    label="If taking prescription medication, who is your prescribing MD? Please include type of MD, name and phone number"
                    :limit=1000
                    inputStyle="min-height:20px;"
                />
                <div class="bottom-20 radios avoid-break">
                    <Radios class="flex radio-x2 border-bottom gray pad-5" v-model="rec.drink_alcohol" label="Do you drink alcohol?" :options='["No","Yes"]' />
                    <Radios class="flex radio-x2 border-bottom gray pad-5" v-model="rec.recreational_drugs" label="Do you use recreational drugs?" :options='["No","Yes"]' />
                    <Radios class="flex radio-x2 border-bottom gray pad-5" v-model="rec.suicidal_thoughts" label="Do you have suicidal thoughts?" :options='["No","Yes"]' />
                    <Radios class="flex radio-x2 border-bottom gray pad-5" v-model="rec.attempted_suicide" label="Have you ever attempted suicide?" :options='["No","Yes"]' />
                    <Radios class="flex radio-x2 border-bottom gray pad-5" v-model="rec.harm_others" label="Do you have thoughts or urges to harm others?" :options='["No","Yes"]' />
                    <Radios class="flex radio-x2 border-bottom gray pad-5" v-model="rec.psychiatric_hospitalized" label="Have you ever been hospitalized for a psychiatric issue?" :options='["No","Yes"]' />
                    <Radios class="flex radio-x2 border-bottom gray pad-5" v-model="rec.mental_illness" label="Is there history of mental illness in your family?" :options='["No","Yes"]' />
                </div>
                <ExpandableTextArea
                    class="avoid-break block"
                    inputClass="block flex-1 do-not-print-me"
                    oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                    type="text"
                    id="relationship_nature"
                    v-model="rec.relationship_nature"
                    label="If you are in a relationship, please describe the nature of the relationship and months or years together."
                    :limit=1000
                    inputStyle="min-height:20px;"
                />
                <ExpandableTextArea
                    class="avoid-break block"
                    inputClass="block flex-1 do-not-print-me"
                    oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                    type="text"
                    id="living_situation"
                    v-model="rec.living_situation"
                    label="Describe your current living situation. Do you live alone, with others, with family, etc..."
                    :limit=1000
                    inputStyle="min-height:20px;"
                />
                <ExpandableTextArea
                    class="avoid-break block"
                    inputClass="block flex-1 do-not-print-me"
                    oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                    type="text"
                    id="education_level"
                    v-model="rec.education_level"
                    label="What is your level of education? Highest grade/degree and type of degree."
                    :limit=1000
                    inputStyle="min-height:20px;"
                />
                <ExpandableTextArea
                    class="avoid-break block"
                    inputClass="block flex-1 do-not-print-me"
                    oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                    type="text"
                    id="current_occupation"
                    v-model="rec.current_occupation"
                    label="What is your current occupation? What do you do? How long have you been doing it?"
                    :limit=1000
                    inputStyle="min-height:20px;"
                />
                <div class="avoid-break">
                    <div class="dark-text top-20 bottom-20 font-13 bold">Please check any of the following you have experienced in the past six months.</div>
                    <div class="columns-2">
                        <Checkbox class="block" v-model="rec.increased_appetite" label="Increased appetite" />
                        <Checkbox class="block" v-model="rec.decreased_appetite" label="Decreased appetite" />
                        <Checkbox class="block" v-model="rec.trouble_concentrating" label="Trouble concentrating" />
                        <Checkbox class="block" v-model="rec.difficulty_sleeping" label="Difficulty sleeping" />
                        <Checkbox class="block" v-model="rec.excessive_sleep" label="Excessive sleep" />
                        <Checkbox class="block" v-model="rec.low_motivation" label="Low motivation" />
                        <Checkbox class="block" v-model="rec.isolation_from_others" label="Isolation from others" />
                        <Checkbox class="block" v-model="rec.fatigue_low_energy" label="Fatigue/low energy" />
                        <Checkbox class="block" v-model="rec.low_self_esteem" label="Low self-esteem" />
                        <Checkbox class="block" v-model="rec.depressed_mood" label="Depressed mood" />
                        <Checkbox class="block" v-model="rec.tearful_or_crying_spells" label="Tearful or crying spells" />
                        <Checkbox class="block" v-model="rec.anxiety_episodes" label="Anxiety" />
                        <Checkbox class="block" v-model="rec.fear_experience" label="Fear" />
                        <Checkbox class="block" v-model="rec.hopelessness_feelings" label="Hopelessness" />
                        <Checkbox class="block bottom-10" v-model="rec.panic_attacks" label="Panic" />
                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block flex-1 do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="other_symptom_effects"
                            v-model="rec.other_symptom_effects"
                            label="Other"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                    </div>
                </div>
                <div class="avoid-break">
                    <div class="dark-text top-20 bottom-20 font-13 bold">Please check any of the following medical history that applies to you</div>
                    <div class="columns-2">
                        <Checkbox class="block" v-model="rec.headache_medical_history" label="Headache" />
                        <Checkbox class="block" v-model="rec.high_blood_pressure" label="High blood pressure" />
                        <Checkbox class="block" v-model="rec.gastritis_or_esophagitis" label="Gastritis or esophagitis" />
                        <Checkbox class="block" v-model="rec.hormone_related_problems" label="Hormone-related problems" />
                        <Checkbox class="block" v-model="rec.head_injury" label="Head injury" />
                        <Checkbox class="block" v-model="rec.angina_or_chest_pain" label="Angina or chest pain" />
                        <Checkbox class="block" v-model="rec.irritable_bowel_syndrome" label="Irritable bowel syndrome" />
                        <Checkbox class="block" v-model="rec.chronic_pain" label="Chronic pain" />
                        <Checkbox class="block" v-model="rec.loss_of_consciousness" label="Loss of consciousness" />
                        <Checkbox class="block" v-model="rec.heart_attack" label="Heart attack" />
                        <Checkbox class="block" v-model="rec.bone_or_joint_problems" label="Bone or joint problems" />
                        <Checkbox class="block" v-model="rec.seizures_experienced" label="Seizures" />
                        <Checkbox class="block" v-model="rec.kidney_related_issues" label="Kidney-related issues" />
                        <Checkbox class="block" v-model="rec.chronic_fatigue" label="Chronic fatigue" />
                        <Checkbox class="block" v-model="rec.dizziness_vertigo" label="Dizziness" />
                        <Checkbox class="block" v-model="rec.faintness_syncope" label="Faintness" />
                        <Checkbox class="block" v-model="rec.heart_valve_problems" label="Heart valve problems" />
                        <Checkbox class="block" v-model="rec.urinary_tract_problems" label="Urinary tract problems" />
                        <Checkbox class="block" v-model="rec.fibromyalgia_fms" label="Fibromyalgia" />
                        <Checkbox class="block" v-model="rec.numbness_tingling" label="Numbness & tingling" />
                        <Checkbox class="block" v-model="rec.shortness_of_breath" label="Shortness of breath" />
                        <Checkbox class="block" v-model="rec.diabetes_mellitus" label="Diabetes" />
                        <Checkbox class="block" v-model="rec.asthma_wheezing" label="Asthma" />
                        <Checkbox class="block" v-model="rec.arthritis_polyarthritis" label="Arthritis" />
                        <Checkbox class="block" v-model="rec.thyroid_issues" label="Thyroid issues" />
                        <Checkbox class="block" v-model="rec.hiv_aids" label="HIV/AIDS" />
                        <Checkbox class="block" v-model="rec.cancer_diagnosis" label="Cancer" />
                        <Checkbox class="block bottom-10" v-model="rec.hepatitis_field" label="Hepatitis" />
                        <ExpandableTextArea
                            class="avoid-break block"
                            inputClass="block flex-1 do-not-print-me"
                            oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                            type="text"
                            id="other_medical_history"
                            v-model="rec.other_medical_history"
                            label="Other"
                            :limit=500
                            inputStyle="min-height:20px;"
                        />
                    </div>
                </div>
                <ExpandableTextArea
                    class="avoid-break block"
                    inputClass="block flex-1 do-not-print-me"
                    oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'"
                    type="text"
                    id="anything_additional_for_therapist"
                    v-model="rec.anything_additional_for_therapist"
                    label="Anything else you would like your therapist or counselor to know?"
                    :limit=1000
                    inputStyle="min-height:20px;"
                />

                <!-- put generated code ABOVE here -->

                <div class="align-right" v-if="!signature_ids.length">
                    <button v-if="original_signature_ids.length && !signature_ids.length" class="align-right inline-block secondary right-15" type="button" @click="signature_ids=original_signature_ids">
                        Cancel Edit
                    </button>
                    <ConfirmButton class="inline-block" @click="updateRecord()" :canedit="isEditable" />

                </div>
                <FormSignature :user_id="user_id" :signature_ids="signature_ids" ref="form_signatures" @getSignatures="getSignatures" v-if="signature_ids.length > 0"/>
                <FormSignatureButton
                    :rec="rec"
                    :original_rec="original_rec"
                    :signatures_info="signatures_info"
                    :fileName="pageMeta.component_name"
                    :signature_ids="signature_ids"
                    :record_id="record_id"
                    @resetView="reloadData"
                    @fail="fail2sign"
                    @showModal="showPdfModal"
                    :save="updateRecord"
                />
            </form>
            <VIfWorkableModal
                :hasCloseButton="false"
                data-html2canvas-ignore="true"
                v-if="showCreatePdfModal"
            >
                <SignConvertPdfModal />            
            </VIfWorkableModal>
        </div>
    </div>
</template>
<script>
    import SignConvertPdfModal from '@/components/formpieces/SignConvertPdfModal'
    import FormClient from '@/components/formpieces/FormClient'
    import FormCompany from '@/components/formpieces/FormCompany.vue';
    import FormSignature from '@/components/formpieces/FormSignatureNew';
    import FormSignatureButton from '@/components/formpieces/FormSignatureButtonNew'
    import VIfWorkableModal from '@/components/general/modals/vIfWorkableModal.vue';
    import { dryalex } from '@/mixins/dryalex'
    import { merge } from 'lodash'

    export default {
        name: 'Intake',
        props: {
            record_id: {
                type: [String, Number],
                required : false,
                default : 0
            },
        },
        components: { FormClient, FormSignature, VIfWorkableModal, FormCompany, FormSignatureButton, SignConvertPdfModal },
        data() {
            return {
                loading     : 1,
                updating    : 0,
                isEditable  : 1,
                user_id     : 0,
                client_id   : 0,
                page_id     : 0,
                rec         : {},
                original_rec : {},
                signature_ids : [],
                original_signature_ids : [],
                showCreatePdfModal : false,
                api_signs_needed: null,
                signatures_info: null,
                client: {},
                company: {},
                rows: [],
                level_id: 0,
                level: 'company',
                options: [],
                this_record_id : 0,
                router_push : 0,
              disabled: false
            }
        },
        methods: {
            async fail2sign() {
                this.showPdfModal(false)
            },
            async reloadData(signature){
                this.showCreatePdfModal = false;
                this.signature_ids.push(signature)
            },
            showPdfModal(bool){
                this.showCreatePdfModal = bool;
            },
            getClient(data){
                this.client = data;
            },
            getCompany(data){
                this.company = data;
            },
            //signatures that come from the FormSignatures component
            getSignatures(data){
                this.signatures_info = data ? data : [];
            },
            async updateRecord(e) {
                if (!this.updating) {
                    this.updating  = 1
                    try{e.preventDefault()}catch(error){/**/}

                    let xthis = await dryalex.form_data_update_record(this, e)
                    merge(this, xthis)
                    if (this.router_push) {
                        this.router_push = 1
                        this.$forceUpdate()
                    }
                    this.original_signature_ids = this.signature_ids
                }
            },
            async init() {

                let xthis = await dryalex.form_data_init(this)
                merge(this, xthis)
                this.loading = 0
            },
        },
        async created() {
            await this.init()
        },
        watch: {
            showCreatePdfModal(newVal){
                if(window.innerWidth >= 768 && newVal == true) this.openModalOverlay();
                if(newVal == false){this.closeModalOverlay()}
            },
        }
    }
</script>